import API from "../utils/api";
import authHeader from "./auth-header";

const register = (username, email, password) => {
  return API.post("signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return API.post("auth/login", {
    username,
    password,
  }).then((response) => {
    if (response?.headers?.["x-access-token"]) {
      localStorage.setItem("access-token", response.headers["x-access-token"]);
      // localStorage.setItem("user", JSON.stringify(response.data));
      return { token: response.headers["x-access-token"] };
    }

    return response.data;
  });
};

const logout = async () => {
  let user;
  try {
    user = await API.get("/auth/logout", { headers: authHeader() });
    // localStorage.removeItem("user");
    // localStorage.removeItem("access-token");
    localStorage.clear();
    return true;
  } catch (error) {
    console.log("getLoginSuccess", error);
    localStorage.clear();
    return null;
  }
};

const getLoginSuccess = async () => {
  // return JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("access-token");
  if (!token) return null;
  let user;
  try {
    user = await API.get("/admin/private", { headers: authHeader() });
    // console.log(user);
    localStorage.setItem("user", JSON.stringify(user.data.message));
    return true;
  } catch (error) {
    console.log("getLoginSuccess", error);
    return null;
  }
};

const getCurrentUser = () => {
  let user = localStorage.getItem("user");
  if (!user) return { username: "", loginAt: "" };
  return JSON.parse(user);
};

export default {
  register,
  login,
  logout,
  getLoginSuccess,
  getCurrentUser,
};
