export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  const access_token = localStorage.getItem("access-token");

  // console.log("authheader-debug", user, access_token);

  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
    // return { "x-token": user.accessToken };
  } else if (access_token) {
    return { Authorization: "Bearer " + access_token };
  } else {
    return {};
  }
}
