import API from "../utils/api";
import authHeader from "./auth-header";

const getUserTable = async () => {
  let response = await API.get("/admin/users", { headers: authHeader() });
  if (response?.data?.message) return response.data.message;

  return [];
};

export default {
  getUserTable,
};
