import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CircularProgress from "@material-ui/core/CircularProgress";

import AdminService from "../../services/admin.service";
import { TableBody } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  tableLoading: {
    marginLeft: "50%",
    marginRight: "50%",
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();

  useEffect(async () => {
    let results = await AdminService.getUserTable();
    console.log(results);
    setUsers(
      results.map((row) => [
        row.id,
        row.username,
        row.email,
        row.status,
        row.loginAt,
      ])
    );
    setIsLoading(false);
  }, [page]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>User Table</h4>
            <p className={classes.cardCategoryWhite}>
              The complete users list for ERP
            </p>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <CircularProgress className={classes.tableLoading} />
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={["ID", "Username", "E-mail", "Status", "LoginAt"]}
                tableData={users}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
